<template>
  <div class="attendance-page mx-2">
    <v-row justify="center">
      <v-col md="6">
        <!-- info -->
        <!-- <v-alert class="my-2" color="primary" dark>
          Choose a subject &amp; date to record the attendance
        </v-alert> -->
        <!-- view/take -->
        <v-card v-if="!currentUser.is_student" outlined class="mt-2 mx-1" flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Attendance History</v-list-item-title>
              <v-list-item-subtitle class="text-wrap"
                >View/edit previously recorded attendance</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="non-focused"
                outlined
                @click="historyButtonClicked"
                color="primary"
                >History</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-card>
        <v-card class="mt-2 mx-1" outlined elevation="0" v-if="!currentUser.is_student">
          <v-tabs
            fixed-tabs
            background-color="transparent"
            color="primary"
            v-model="takeViewAttendanceTab"
          >
            <v-tab v-if="!currentUser.is_student" class="non-focused tab-text"
              >Take Attendance</v-tab
            >
            <v-tab
              :class="
                `non-focused tab-text ${
                  currentUser.is_student ? 'disable-events' : ''
                }`
              "
              >View Attendance</v-tab
            >
          </v-tabs>
          <v-card-text class="pa-0">
            <v-tabs-items class="ma-1" v-model="takeViewAttendanceTab">
              <v-tab-item v-if="!currentUser.is_student">
                <div>
                  <!-- date filter for taking attendance -->
                  <v-dialog
                    ref="takeAttendanceDialog"
                    v-model="takeAttendanceDateModal"
                    :return-value.sync="takeAttendanceDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        class="ma-1"
                        :value="formattedTakeAttendanceDateString"
                        label="Select a Date"
                        hint="This is the date that will be used when taking attendance"
                        persistent-hint
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details="auto"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="takeAttendanceDate">
                      <v-row no-gutters>
                        <v-col class="text-right">
                          <v-btn
                            text
                            color="primary"
                            @click="takeAttendanceDateModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            color="primary"
                            @click="
                              $refs.takeAttendanceDialog.save(
                                takeAttendanceDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </v-tab-item>
              <v-tab-item>
                <div>
                  <!-- date range filter for viewing attendance -->
                  <v-dialog
                    ref="viewAttendanceDialog"
                    v-model="viewAttendanceDateModal"
                    :return-value.sync="viewAttendanceDate"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        solo
                        class="ma-1"
                        :value="formattedViewAttendanceDateRangeString"
                        label="Select a Date Range"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        hide-details="auto"
                        hint="All attendance records between these two dates will be displayed"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="viewAttendanceDate" range>
                      <v-row no-gutters>
                        <v-col cols="12" class="text-center">
                          <span
                            v-if="
                              viewAttendanceDate &&
                                viewAttendanceDate.length == 1
                            "
                            class="text-caption red--text"
                            >Please select End Date as well</span
                          >
                        </v-col>
                        <v-col class="text-right">
                          <v-btn
                            text
                            color="primary"
                            @click="viewAttendanceDateModal = false"
                          >
                            Cancel
                          </v-btn>
                          <v-btn
                            text
                            :disabled="
                              viewAttendanceDate &&
                                viewAttendanceDate.length == 1
                            "
                            color="primary"
                            @click="
                              $refs.viewAttendanceDialog.save(
                                viewAttendanceDate
                              )
                            "
                          >
                            OK
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-date-picker>
                  </v-dialog>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </v-card-text>
          <v-row class="mx-2 my-2">
            <v-col cols="12" class="text-center">
              <v-btn
                class="non-focused"
                color="primary"
                block
                @click="handleDailyAttendanceClick"
                >Proceed</v-btn
              >
            </v-col>
          </v-row>
        </v-card>

        <!-- Daily attendance -->
        <!-- <v-card outlined class="mt-2 mx-1" flat>
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>Daily Attendance</v-list-item-title>
              <v-list-item-subtitle class="text-wrap">{{
                currentUser.is_student
                  ? "You can view the daily attendance or subject-wise from the list below."
                  : "You can take daily attendance or subject-wise from the list below."
              }}</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                class="non-focused"
                color="blue"
                outlined
                @click="handleDailyAttendanceClick"
                >Daily</v-btn
              >
            </v-list-item-action>
          </v-list-item>
        </v-card> -->

        <!-- subjects list -->
        <!-- <intract-smart-list
          ref="attendance-subjects-smart-list"
          class="mt-2"
          disable-search
          v-if="subjectsEndpoint"
          :endpoint="subjectsEndpoint"
        >
          <template v-slot:list-item="{ item: subject }">
            <v-list-item class="mx-0" @click="handleSubjectClick(subject)">
              <v-list-item-content>
                <v-list-item-title class="font-weight-medium">{{
                  subject.name
                }}</v-list-item-title>
                <v-list-item-subtitle>{{
                  subject.room ? subject.room.sections : "Extra Subject"
                }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon
                ><v-icon>mdi-arrow-right</v-icon></v-list-item-icon
              >
            </v-list-item>
            <v-divider class="mx-4"></v-divider>
          </template>
        </intract-smart-list> -->
      </v-col>
    </v-row>
    <choose-room-dialog
      :visible="showRoomDialog"
      @roomSelected="
        (r) => {
          selectedRoom = r;
          showRoomDialog = false;
          if (currentUser.is_faculty && !currentUser.is_admin) {
            handleDailyAttendanceClick();
          }
        }
      "
    />
  </div>
</template>
<script>
// import IntractSmartList from "@components/generics/IntractSmartList";
import Mixins from "@utils/mixins";
import ChooseRoomDialog from "@components/dialogs/ChooseRoomDialog";
import EventBus from "@utils/event_bus";
import moment from "moment";

export default {
  name: "Attendance",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    ChooseRoomDialog,
    // IntractSmartList,
  },
  data() {
    return {
      viewAttendanceDate: null,
      viewAttendanceDateModal: false,

      takeAttendanceDate: null,
      takeAttendanceDateModal: false,

      takeViewAttendanceTab: null,
      selectedRoom: null,
      showRoomDialog: false,
    };
  },
  computed: {
    shouldProceed() {
      return (
        ((this.currentUser.is_admin || this.currentUser.is_student) &&
          this.selectedRoom != null) ||
        (this.currentUser.is_faculty && !this.currentUser.is_admin)
      );
    },
    // subjectsEndpoint() {
    //   if (!this.shouldProceed) return null;
    //   if (this.currentUser.is_student || this.currentUser.is_admin)
    //     return this.Helper.addUrlParams(
    //       this.endpoints.subjectViewSet,
    //       "room=" + this.selectedRoom.id
    //     );
    //   // if faculty
    //   return this.Helper.addUrlParams(
    //     this.endpoints.subjectViewSet,
    //     "faculty=" + this.currentUser.id
    //   );
    // },
    formattedTakeAttendanceDateString() {
      if (!this.takeAttendanceDate) return null;
      return moment(this.takeAttendanceDate).format("ll");
    },
    formattedViewAttendanceDateRangeString() {
      if (!this.viewAttendanceDate) return null;
      if (this.viewAttendanceDate.length == 0) return "";
      else if (this.viewAttendanceDate.length == 1)
        return moment(this.viewAttendanceDate[0]).format("ll") + " - ";
      else
        return (
          moment(this.viewAttendanceDate[0]).format("ll") +
          " - " +
          moment(this.viewAttendanceDate[1]).format("ll")
        );
    },
  },
  methods: {
    // async handleSubjectClick(subject) {
    //   var throwDateError = () =>
    //     this.showSnackbar({ title: "Date is mandatory!", type: "error" });

    //   if (this.currentUser.is_student) {
    //     this.$router.push({
    //       name: "StudentAttendance",
    //       params: {
    //         studentId: this.currentUser.id,
    //         subjectId: subject.id,
    //         startDate: this.viewAttendanceDate[0],
    //         endDate: this.viewAttendanceDate[1],
    //       },
    //     });
    //   } else if (this.takeViewAttendanceTab == 0) {
    //     if (!this.takeAttendanceDate) throwDateError();
    //     // take attendance
    //     this.$router.push({
    //       name: "TakeAttendance",
    //       params: {
    //         room: subject.room,
    //         subject: subject,
    //         attendanceDate: this.takeAttendanceDate,
    //       },
    //     });
    //   } else if (this.takeViewAttendanceTab == 1) {
    //     if (!this.viewAttendanceDate) throwDateError();
    //     // view attendance
    //     this.$router.push({
    //       name: "ClassroomAttendance",
    //       params: {
    //         roomId: subject.room ? subject.room.id : null,
    //         subjectId: subject.id,
    //         startDate: this.viewAttendanceDate[0],
    //         endDate: this.viewAttendanceDate[1],
    //         subject: subject,
    //       },
    //     });
    //   }
    // },

    async handleDailyAttendanceClick() {
      if (!this.selectedRoom) {
        this.showRoomDialog = true;
        return;
      }
      var throwDateError = () =>
        this.showSnackbar({ title: "Date is mandatory!", type: "error" });

      if (this.currentUser.is_student) {
        this.$router.push({
          name: "StudentAttendanceDaily",
          params: {
            roomId: this.currentUser.room.id,
            studentId: this.currentUser.id,
            daily: true,
            startDate: this.viewAttendanceDate[0],
            endDate: this.viewAttendanceDate[1],
          },
        });
      } else if (this.takeViewAttendanceTab == 0) {
        if (!this.takeAttendanceDate) throwDateError();
        // take attendance
        if (
          this.currentUser.is_admin || this.selectedRoom.class_teachers &&
          this.selectedRoom.class_teachers.map(ct => ct.id).includes(this.currentUser.id)
        ) {
          this.$router.push({
            name: "TakeAttendance",
            params: {
              room: this.selectedRoom,
              daily: true,
              attendanceDate: this.takeAttendanceDate,
            },
          });
        } else {
          this.showSnackbar({
            title:
              "Only class teacher of this class can take the daily attendance",
            type: "error",
          });
          return;
        }
      } else if (this.takeViewAttendanceTab == 1) {
        if (!this.viewAttendanceDate) throwDateError();
        // view attendance
        if (
          this.currentUser.is_admin || this.selectedRoom.class_teachers && 
          this.selectedRoom.class_teachers.map(ct => ct.id).includes(this.currentUser.id)
        ) {
          this.$router.push({
            name: "ClassroomAttendanceDaily",
            params: {
              roomId: this.selectedRoom.id,
              startDate: this.viewAttendanceDate[0],
              endDate: this.viewAttendanceDate[1],
              daily: true,
            },
          });
        } else {
          this.showSnackbar({
            title:
              "Only class teacher of this class can view the daily attendance",
            type: "error",
          });
          return;
        }
      }
    },

    async historyButtonClicked() {
      this.$router.push({
        name: "AttendanceHistory",
        params: { roomId: this.selectedRoom?.id },
      });
    },
    // async onRefresh() {
    //   if (this.$refs["attendance-subjects-smart-list"]) {
    //     this.$refs["attendance-subjects-smart-list"].getItems();
    //   }
    // },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Home") {
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "Attendance");
    }
    next();
  },
  created() {
    this.showRoomDialog = this.currentUser.is_admin;
    // initialize dates
    this.takeAttendanceDate = moment().format("YYYY-MM-DD");
    this.viewAttendanceDate = [
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ];
  },
};
</script>
<style lang="sass" scoped>
.tab-text
  font-size: 12px

.disable-events
  pointer-events: none
</style>
