var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"attendance-page mx-2"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[(!_vm.currentUser.is_student)?_c('v-card',{staticClass:"mt-2 mx-1",attrs:{"outlined":"","flat":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Attendance History")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v("View/edit previously recorded attendance")])],1),_c('v-list-item-action',[_c('v-btn',{staticClass:"non-focused",attrs:{"outlined":"","color":"primary"},on:{"click":_vm.historyButtonClicked}},[_vm._v("History")])],1)],1)],1):_vm._e(),(!_vm.currentUser.is_student)?_c('v-card',{staticClass:"mt-2 mx-1",attrs:{"outlined":"","elevation":"0"}},[_c('v-tabs',{attrs:{"fixed-tabs":"","background-color":"transparent","color":"primary"},model:{value:(_vm.takeViewAttendanceTab),callback:function ($$v) {_vm.takeViewAttendanceTab=$$v},expression:"takeViewAttendanceTab"}},[(!_vm.currentUser.is_student)?_c('v-tab',{staticClass:"non-focused tab-text"},[_vm._v("Take Attendance")]):_vm._e(),_c('v-tab',{class:("non-focused tab-text " + (_vm.currentUser.is_student ? 'disable-events' : ''))},[_vm._v("View Attendance")])],1),_c('v-card-text',{staticClass:"pa-0"},[_c('v-tabs-items',{staticClass:"ma-1",model:{value:(_vm.takeViewAttendanceTab),callback:function ($$v) {_vm.takeViewAttendanceTab=$$v},expression:"takeViewAttendanceTab"}},[(!_vm.currentUser.is_student)?_c('v-tab-item',[_c('div',[_c('v-dialog',{ref:"takeAttendanceDialog",attrs:{"return-value":_vm.takeAttendanceDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.takeAttendanceDate=$event},"update:return-value":function($event){_vm.takeAttendanceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"solo":"","value":_vm.formattedTakeAttendanceDateString,"label":"Select a Date","hint":"This is the date that will be used when taking attendance","persistent-hint":"","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto"}},'v-text-field',attrs,false),on))]}}],null,false,1865357358),model:{value:(_vm.takeAttendanceDateModal),callback:function ($$v) {_vm.takeAttendanceDateModal=$$v},expression:"takeAttendanceDateModal"}},[_c('v-date-picker',{model:{value:(_vm.takeAttendanceDate),callback:function ($$v) {_vm.takeAttendanceDate=$$v},expression:"takeAttendanceDate"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.takeAttendanceDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.$refs.takeAttendanceDialog.save(
                              _vm.takeAttendanceDate
                            )}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)]):_vm._e(),_c('v-tab-item',[_c('div',[_c('v-dialog',{ref:"viewAttendanceDialog",attrs:{"return-value":_vm.viewAttendanceDate,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.viewAttendanceDate=$event},"update:return-value":function($event){_vm.viewAttendanceDate=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"ma-1",attrs:{"solo":"","value":_vm.formattedViewAttendanceDateRangeString,"label":"Select a Date Range","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto","hint":"All attendance records between these two dates will be displayed","persistent-hint":""}},'v-text-field',attrs,false),on))]}}],null,false,719432967),model:{value:(_vm.viewAttendanceDateModal),callback:function ($$v) {_vm.viewAttendanceDateModal=$$v},expression:"viewAttendanceDateModal"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.viewAttendanceDate),callback:function ($$v) {_vm.viewAttendanceDate=$$v},expression:"viewAttendanceDate"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(
                            _vm.viewAttendanceDate &&
                              _vm.viewAttendanceDate.length == 1
                          )?_c('span',{staticClass:"text-caption red--text"},[_vm._v("Please select End Date as well")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.viewAttendanceDateModal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.viewAttendanceDate &&
                              _vm.viewAttendanceDate.length == 1,"color":"primary"},on:{"click":function($event){return _vm.$refs.viewAttendanceDialog.save(
                              _vm.viewAttendanceDate
                            )}}},[_vm._v(" OK ")])],1)],1)],1)],1)],1)])],1)],1),_c('v-row',{staticClass:"mx-2 my-2"},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"non-focused",attrs:{"color":"primary","block":""},on:{"click":_vm.handleDailyAttendanceClick}},[_vm._v("Proceed")])],1)],1)],1):_vm._e()],1)],1),_c('choose-room-dialog',{attrs:{"visible":_vm.showRoomDialog},on:{"roomSelected":function (r) {
        _vm.selectedRoom = r;
        _vm.showRoomDialog = false;
        if (_vm.currentUser.is_faculty && !_vm.currentUser.is_admin) {
          _vm.handleDailyAttendanceClick();
        }
      }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }